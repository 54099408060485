import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmCustomNfIncorporateEvents extends Event {
    public state = {
        loading: false,
    }
     static converterData(dataBrasileira: string | any) {
        // console.log("entrei aqui :>", dataBrasileira)
        if (dataBrasileira) {
            const dataBrasileiraSplit = dataBrasileira.split("/")
        // console.log("entrei aqui :2>", dataBrasileiraSplit)

            const mes = dataBrasileiraSplit[1];
            const dia = dataBrasileiraSplit[0];
            const ano = dataBrasileiraSplit[2];
            // const dataToTimestamp = `${dia}/${mes}/${ano}`;
            const dataToTimestamp = `${ano}-${mes}-${dia}`;
            return dataToTimestamp;
        }
    }
    public onChange = async (value: any, setField: any, setState: any, values: string) => {
        if(value){
            // console.log("valor do xml", value)
            this.setState("loadings",[
                "psm_num_nf_id",
                "psm_nitem",
                "psm_fk_person_id",
                "psm_shipment_type",
                "psm_shipping_company_fk_person_id",
                "psm_fk_company_id",
                "psm_acess_key",
                "psm_shipment_type_fk_type_frete_id",
                "psm_emission_date",
                "psm_manual_note",
                "psm_product_name_nf",
                "psm_number_product_nf"
            ]);
            this.setFieldValue("psm_num_nf_id", `0000${value.dadosXml[1].nNf}`);
            this.setFieldValue("psm_acess_key", value.dadosXml[1].chaveNfe);
            this.setFieldValue("psm_nitem", value.numItem);

            console.log("psm_emission_date :::::>", PsmCustomNfIncorporateEvents.converterData(value.dadosXml[1].dataEmi))

            this.setFieldValue("psm_emission_date", PsmCustomNfIncorporateEvents.converterData(value.dadosXml[1].dataEmi))

            this.setFieldValue("psm_product_name_nf",  value.descriptionItens)
            this.setFieldValue("psm_number_product_nf", value.numItem)

        

            if(value.isImport == true){
                this.setField("psm_manual_note", {
                    checkedType: false,
                    disabled: true
                })
                this.setField("psm_fk_person_id", {
                    advancedSearch: false
                })             
            }

            axios
                .get("/api/v1/receipts/check-client/" + value.dadosXml[1].CNPJ)
                .then((res) => {
                    var dateClient = res.data.response;

                    if(dateClient){
                        this.setState("loadings",[
                            "psm_fk_person_id"
                        ])
                        this.setFieldValue("psm_fk_person_id", dateClient[0].person_id);
                        this.setState("loadings", [])
                    }
                })
            axios
                .get("api/v1/receipts/check-companie/" + value.dadosXml[1].destMxCnpj)
                .then((res) => {
                    var companie = res.data.response;
                    console.log('Companie ::::: ', companie);
                    this.setState("loadings",[
                        "psm_fk_company_id"
                    ])
                    this.setFieldValue("psm_fk_company_id",companie.company_id)
                    this.setState("loadings", [])
                })
            if((value.dadosXml[1].typeFret !== '2' ) && (value.dadosXml[1].typeFret !== '3' ) && (value.dadosXml[1].typeFret !== '4' )
                && (value.dadosXml[1].typeFret !== '9' )){
                axios
                    .get("api/v1/receipts/check-frete/" + value.dadosXml[1].typeFret)
                    .then((res) => {
                        var typeFrete = res.data.response;
                        this.setState("loadings",[
                            "psm_shipment_type_fk_type_frete_id"
                        ])
                        this.setFieldValue("psm_shipment_type_fk_type_frete_id", typeFrete.type_frete_id);
                        this.setState("loadings", [])
                    })
            }
            
            if(value.dadosXml[1].cnpjTransp !== null){
                await axios
                .get("/api/v1/receipts/check-transp/" + value.dadosXml[1].cnpjTransp)
                .then((res) => {
                    this.setState("loadings",[
                        "psm_shipping_company_fk_person_id"
                    ])
                    var dateClient = res.data.response == null ? null : res.data.response;
                    if(dateClient !== null){
                        this.setFieldValue("psm_shipping_company_fk_person_id", dateClient.person_id);
                    }
                })
                .finally(() => {
                    this.setState("loadings", []);
                });
            }else{
                this.setState("loadings", []);
            }
        }
    }
}